<template>
  <v-layout column>
    <v-row align="center" justify="center">
      <v-col xs="9" sm="9" md="6" lg="6" xl="6" class="align-center">
        <div class="align-center text-center mt-2">
          <v-card elevation="6" max-width="600" style="margin: auto">
            <v-toolbar flat dense class="blue darken-3" dark>
              <v-toolbar-title v-if="this.$vuetify.breakpoint.width > 900">
                <span style="font-size: 20px">{{
                  $t("titles.registerPage")
                }}</span></v-toolbar-title
              >
              <v-toolbar-title v-if="this.$vuetify.breakpoint.width < 900">
                <span style="font-size: 20px">{{
                  $t("titles.registerPage")
                }}</span></v-toolbar-title
              >

              <!-- <v-spacer></v-spacer>

              <v-btn
                class="grey lighten-3 black--text mr-4"
                v-if="!$store.state.isUserLoggedIn"
                @click="navigateTo({ name: 'register' })"
              >
                <v-icon>person_outline</v-icon>
                {{ $t("button.individual") }}
              </v-btn>

              <v-btn
                class="grey lighten-3 black--text"
                v-if="!$store.state.isUserLoggedIn"
                @click="navigateTo({ name: 'registerCompany' })"
              >
                <v-icon class="mr-1">work_outline</v-icon>
                {{ $t("button.company") }}
              </v-btn> -->
            </v-toolbar>

            <div class="pl-4 pr-4 pt-2 pb-4">
              <form name="register-form" autocomplete="off">
                <v-text-field
                  v-model="username"
                  label="Username"
                  filled
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  label="Email"
                  filled
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  :append-icon="value ? 'visibility' : 'visibility_off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'"
                  filled
                ></v-text-field>
              </form>
              <v-select
                :items="serversName"
                v-model="selectedServerName"
                :label="$t('button.chooseServer')"
                filled
                dense
              >
              </v-select>
              <div class="red--text" v-html="error" />

              <v-btn
                class="white--text"
                color="blue darken-3"
                style="min-width: 15vw"
                elevation="2"
                @click="register"
              >
                <v-icon>app_registration</v-icon>
                {{ $t("button.register") }}
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import HelperMethods from "@/utilities/HelperMethods";
import ServersService from "@/services/ServersService";

export default {
  data() {
    return {
      username: "",
      email: "",
      password: "",
      value: String,
      serversName: [],
      selectedServerName: null,
      servers: null,
      error: null,
    };
  },

  async mounted() {
    try {
      let response = await ServersService.getServersList();

      this.servers = response.data;

      for (let i = 0; i < this.servers.length; i++) {
        this.serversName.push(this.servers[i].name);
      }
    } catch (err) {
      console.log(err);
    }
  },

  methods: {
    async register() {

      // If no server was selected block the login attempt and throw an error
      if (this.selectedServerName === null) {
        this.error =
          "Select a server before registering.";
        return;
      }
      
      let serverUrl = HelperMethods.getServerUrlFromServerName(
        this.servers,
        this.selectedServerName
      );

      // Store on the vuex store the, selected by the user, server
      this.$store.dispatch("setServer", serverUrl);

      try {
        const response = await AuthenticationService.register({
          username: this.username,
          email: this.email,
          password: this.password,
        });
        console.log(response);

        this.$router.push({
          name: "accountValidation",
          query: { redirect: "/accountValidation" },
        });
      } catch (error) {
        this.error = error.response.data.error;
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped></style>
